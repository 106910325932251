import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import TransactionTable from './TransactionTable';
import { UserContext } from './UserContext';
import baseUrl from '../utils/settings';

axios.defaults.withCredentials = true;
const JournalEntries = () => {
    // manage the data needed for listing all entries/transactions/double entries
    const [transactions, setTransactions] = useState();
    const { company } = useContext(UserContext);

    // using usefeect to fetch all the journal entries/transactions
    useEffect(() => {
        const fetchTransactions = async() => {
            try {
                const response = await axios.get(`${baseUrl}/alltransactions`)
                setTransactions(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        fetchTransactions();
    }, [setTransactions])

  return (
    <div className='container'>
        <TransactionTable transactions={transactions} company={company}/>
    </div>
  )
}

export default JournalEntries
